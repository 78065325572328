import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import photoHome from "../../static/img/foto_home_header.jpg";

// eslint-disable-next-line
export const ContactPageTemplate = class extends React.Component {


  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#374836');
	}

  render() {

  return (
      <div className="contact">
        <section className="header compact no-background">
          <div className="container">
            <h1 className="title">CONTACT</h1>
          </div>
        </section>
        <section className="content container mt-5 mb-5">
          <p>Heeft u nog vragen of wilt u graag een afspraak maken voor een offerte? <br/> Aarzel niet om ons te contacteren!</p>
        </section>
        <section className="content container mt-5" style={{marginBottom: '15rem'}}> 
          <div className="row">
            <div className="col-md-8">
            <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/success"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <input
                    className="form-control form-control-lg form-control-tp"
                    name="name"
                    id="name"
                    type="text"
                    required
                    placeholder="NAAM*"
                  />
                  <label htmlFor="name">NAAM</label>
                  <input
                    className="form-control form-control-lg form-control-tp"
                    name="email"
                    type="email"
                    id="email"
                    required
                    placeholder="E-MAIL*"
                  />
                  <label htmlFor="email">E-MAIL</label>

                  <textarea
                    className="form-control form-control-lg form-control-tp"
                    name="message"
                    rows="4"
                    cols="50"
                    id="message"
                    required
                    placeholder="BERICHT*"
                  ></textarea>
                  <label htmlFor="message">BERICHT</label>

                  <button className="tp-btn" type="submit">
                    Verzenden
                  </button>
                </form>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <div className="foto-prinsen-tp" style={{background: `url(${photoHome}) top center / cover no-repeat`}}></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
